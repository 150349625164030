import {Permission} from "#/android/components/Permission";
import {autoWebViewJs} from "#/android/AutoWebViewJs";
import {PermissionAskUi} from "#/android/components/PermissionAskUi";
import {PermissionAskUiConf, PermissionConf} from "#/android/components/PermissionInterfaces";
import i18n from "i18next";
import {storageKeys} from "#/common/storage/storageKeys";
import {Manifest} from "#/android/components/Manifest";
import {data} from "dom7";

/**
 * ask back data
 * class A11yServiceLiveInfo {
 *     var hasPermission = false
 *     var hasService = false
 *     var isAlive = false
 *     var pingTime = 0L
 *     var test = "abc"
 * }
 */
function callback(isOk:boolean, rejectMsg:string):void{

}


function createPermissionConf(askUi:PermissionAskUi){
    let conf:PermissionConf = {
        askUi: askUi, callback,  permissionId: 0,
        scripts: {
            ask: {
                script:"",
                isRunner:false
            },
            check: {
                script:``,
                isRunner:false
            }
        },
        listenResume:true,
    }
    return conf
}

function createAskUi(){
    let conf:PermissionAskUiConf = {
        cancelButtonText: i18n.t("common.cancel"),
        checkBoxText: i18n.t("permissions.a11y.askPermission.checkBoxText"),
        hasCheckBox: true,
        okButtonText: i18n.t("common.ok"),
        storageKey: storageKeys.permissions.a11y.showAsk,
        text: i18n.t("permissions.a11y.askPermission.content"),
        title:i18n.t("permissions.a11y.askPermission.title")
    }
    let askUi = new PermissionAskUi();
    askUi.conf = conf;
    return askUi
}

export function createA11yPermission(){
    let askUi = createAskUi();
    let permissionConf = createPermissionConf(askUi)
    let permission = new A11yPermission();
    permission.conf = permissionConf
    return permission;
}
export class A11yPermission extends Permission{
    constructor() {
        super();
        this.androidPermissionList = [
            Manifest.Permission.BIND_ACCESSIBILITY_SERVICE
        ]
    }
    runCheck(){
        let {print, error, warn, logger} = this.logger.sub(false, "fn_ask");
        let res = autoWebViewJs.onCallGetA11yServiceLiveInfo();
        let {errMsg, data} = res;
        if(errMsg){
            error(errMsg)
            error(errMsg);
            return undefined
        }
        print("data2 is", res);
        print("data3 is", res?.hasPermission);
        return data?.hasPermission
    }
    ask(){
        autoWebViewJs.onCallOpenA11yPermission()
    }
}