import {DataEvent} from "#/libs/fanfanlo/events/DataEvent";
import {Permission} from "#/android/components/Permission";

export class PermissionEvent extends DataEvent{
    bl:boolean|undefined
    extra:any
    permission!:Permission
    constructor(type:any, bl:boolean|undefined, extra:any, permission:Permission) {
        super(type, {bl, extra, permission});
        this.bl = bl;
        this.extra = extra
        this.permission = permission
    }

}