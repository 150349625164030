import {createFloatPermission} from "#/android/components/floatPermission";
import {createA11yPermission} from "#/android/components/A11yPermission";
import {PermissionGroup} from "#/android/components/PermissionGroup";
import {Manifest} from "#/android/components/Manifest";
import {Permission} from "#/android/components/Permission";
const P = Manifest.Permission;
const map = {
    [P.BIND_ACCESSIBILITY_SERVICE]:createA11yPermission,
    [P.SYSTEM_ALERT_WINDOW]:createFloatPermission
}
export function createFloatA11yPermissionGroup(){
    return createPermissionsGroup(P.SYSTEM_ALERT_WINDOW,
        P.BIND_ACCESSIBILITY_SERVICE);
}

export function createPermissionsGroup(...args:string[]):PermissionGroup{
    let list:Permission[] = [];
    for (let i = 0; i < args.length; i++) {
        let name = args[i];
        let f = map[name];
        if(!f){
            throw new Error("no permission registed " + name)
        }
        list.push(f())
    }
    let group = new PermissionGroup(list);
    return group;
}

export async function runPermissionGroup(...permissions:string[]):Promise<PermissionGroup>{
    return new Promise((resolve, reject)=>{
        let group = createPermissionsGroup(...permissions);
        group.addEventListener(PermissionGroup.eventComplete, ()=>{
            if(group.isSuccess){
                resolve(group);
            }else{
                reject(group);
            }
        })
        group.run();
    })
}