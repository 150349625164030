import {storage} from "../../storage/storage";

export function askA11yPermissionDialogCreate(text, hasCheckBox, checkBoxLocalStorageKey){
    var dialog = document.createElement("dialog")
    dialog.innerHTML = `<div>
<div>
<p>${text}</p>
${hasCheckBox? '<p><input id="a11yNoShow" type="checkbox">半个月内直接去授权不再显示该窗口</p> ': ""}
</div>
<div><a id="a11yDialogCancel" href="#" >取消</a>
<a id="a11yDialogOk">确认</a></div>
</div>`
    document.body.appendChild(dialog);
    dialog.showModal();

    let closeBtn = document.getElementById("a11yDialogCancel")
    closeBtn.onclick = function (){
        closeDialog();
    }
    let okBtn = document.getElementById("a11yDialogOk")
    okBtn.onclick = function (){
        if(hasCheckBox){
            let input = document.getElementById("a11yNoShow")
            let checked  = input.checked
            if(checked){
                let o = {hide:true, time:Date.now()}
                storage.setItem(checkBoxLocalStorageKey, o);
            }
        }
        android.onCallOpenA11yPermission()
        closeDialog();
    }
    function closeDialog(){
        dialog.close(true)
        document.body.removeChild(dialog)
        // dialog.destroy()
    }
}

