
class PermissionGroup{

    static ACTIVITY_RECOGNITION = "android.permission-group.ACTIVITY_RECOGNITION";
    static CALENDAR = "android.permission-group.CALENDAR";
    static CALL_LOG = "android.permission-group.CALL_LOG";
    static CAMERA = "android.permission-group.CAMERA";
    static CONTACTS = "android.permission-group.CONTACTS";
    static LOCATION = "android.permission-group.LOCATION";
    static MICROPHONE = "android.permission-group.MICROPHONE";
    static NEARBY_DEVICES = "android.permission-group.NEARBY_DEVICES";
    static NOTIFICATIONS = "android.permission-group.NOTIFICATIONS";
    static PHONE = "android.permission-group.PHONE";
    static READ_MEDIA_AURAL = "android.permission-group.READ_MEDIA_AURAL";
    static READ_MEDIA_VISUAL = "android.permission-group.READ_MEDIA_VISUAL";
    static SENSORS = "android.permission-group.SENSORS";
    static SMS = "android.permission-group.SMS";
    static STORAGE = "android.permission-group.STORAGE";
}
class Permission{

    static ACCEPT_HANDOVER = "android.permission.ACCEPT_HANDOVER";
    static ACCESS_BACKGROUND_LOCATION = "android.permission.ACCESS_BACKGROUND_LOCATION";
    static ACCESS_BLOBS_ACROSS_USERS = "android.permission.ACCESS_BLOBS_ACROSS_USERS";
    static ACCESS_CHECKIN_PROPERTIES = "android.permission.ACCESS_CHECKIN_PROPERTIES";
    static ACCESS_COARSE_LOCATION = "android.permission.ACCESS_COARSE_LOCATION";
    static ACCESS_FINE_LOCATION = "android.permission.ACCESS_FINE_LOCATION";
    static ACCESS_LOCATION_EXTRA_COMMANDS = "android.permission.ACCESS_LOCATION_EXTRA_COMMANDS";
    static ACCESS_MEDIA_LOCATION = "android.permission.ACCESS_MEDIA_LOCATION";
    static ACCESS_NETWORK_STATE = "android.permission.ACCESS_NETWORK_STATE";
    static ACCESS_NOTIFICATION_POLICY = "android.permission.ACCESS_NOTIFICATION_POLICY";
    static ACCESS_WIFI_STATE = "android.permission.ACCESS_WIFI_STATE";
    static ACCOUNT_MANAGER = "android.permission.ACCOUNT_MANAGER";
    static ACTIVITY_RECOGNITION = "android.permission.ACTIVITY_RECOGNITION";
    static ADD_VOICEMAIL = "com.android.voicemail.permission.ADD_VOICEMAIL";
    static ANSWER_PHONE_CALLS = "android.permission.ANSWER_PHONE_CALLS";
    static BATTERY_STATS = "android.permission.BATTERY_STATS";
    static BIND_ACCESSIBILITY_SERVICE = "android.permission.BIND_ACCESSIBILITY_SERVICE";
    static BIND_APPWIDGET = "android.permission.BIND_APPWIDGET";
    static BIND_AUTOFILL_SERVICE = "android.permission.BIND_AUTOFILL_SERVICE";
    static BIND_CALL_REDIRECTION_SERVICE = "android.permission.BIND_CALL_REDIRECTION_SERVICE";
    static BIND_CARRIER_MESSAGING_CLIENT_SERVICE = "android.permission.BIND_CARRIER_MESSAGING_CLIENT_SERVICE";
    /** @deprecated */

    static BIND_CARRIER_MESSAGING_SERVICE = "android.permission.BIND_CARRIER_MESSAGING_SERVICE";
    static BIND_CARRIER_SERVICES = "android.permission.BIND_CARRIER_SERVICES";
    /** @deprecated */

    static BIND_CHOOSER_TARGET_SERVICE = "android.permission.BIND_CHOOSER_TARGET_SERVICE";
    static BIND_COMPANION_DEVICE_SERVICE = "android.permission.BIND_COMPANION_DEVICE_SERVICE";
    static BIND_CONDITION_PROVIDER_SERVICE = "android.permission.BIND_CONDITION_PROVIDER_SERVICE";
    static BIND_CONTROLS = "android.permission.BIND_CONTROLS";
    static BIND_CREDENTIAL_PROVIDER_SERVICE = "android.permission.BIND_CREDENTIAL_PROVIDER_SERVICE";
    static BIND_DEVICE_ADMIN = "android.permission.BIND_DEVICE_ADMIN";
    static BIND_DREAM_SERVICE = "android.permission.BIND_DREAM_SERVICE";
    static BIND_INCALL_SERVICE = "android.permission.BIND_INCALL_SERVICE";
    static BIND_INPUT_METHOD = "android.permission.BIND_INPUT_METHOD";
    static BIND_MIDI_DEVICE_SERVICE = "android.permission.BIND_MIDI_DEVICE_SERVICE";
    static BIND_NFC_SERVICE = "android.permission.BIND_NFC_SERVICE";
    static BIND_NOTIFICATION_LISTENER_SERVICE = "android.permission.BIND_NOTIFICATION_LISTENER_SERVICE";
    static BIND_PRINT_SERVICE = "android.permission.BIND_PRINT_SERVICE";
    static BIND_QUICK_ACCESS_WALLET_SERVICE = "android.permission.BIND_QUICK_ACCESS_WALLET_SERVICE";
    static BIND_QUICK_SETTINGS_TILE = "android.permission.BIND_QUICK_SETTINGS_TILE";
    static BIND_REMOTEVIEWS = "android.permission.BIND_REMOTEVIEWS";
    static BIND_SCREENING_SERVICE = "android.permission.BIND_SCREENING_SERVICE";
    static BIND_TELECOM_CONNECTION_SERVICE = "android.permission.BIND_TELECOM_CONNECTION_SERVICE";
    static BIND_TEXT_SERVICE = "android.permission.BIND_TEXT_SERVICE";
    static BIND_TV_INPUT = "android.permission.BIND_TV_INPUT";
    static BIND_TV_INTERACTIVE_APP = "android.permission.BIND_TV_INTERACTIVE_APP";
    static BIND_VISUAL_VOICEMAIL_SERVICE = "android.permission.BIND_VISUAL_VOICEMAIL_SERVICE";
    static BIND_VOICE_INTERACTION = "android.permission.BIND_VOICE_INTERACTION";
    static BIND_VPN_SERVICE = "android.permission.BIND_VPN_SERVICE";
    static BIND_VR_LISTENER_SERVICE = "android.permission.BIND_VR_LISTENER_SERVICE";
    static BIND_WALLPAPER = "android.permission.BIND_WALLPAPER";
    static BLUETOOTH = "android.permission.BLUETOOTH";
    static BLUETOOTH_ADMIN = "android.permission.BLUETOOTH_ADMIN";
    static BLUETOOTH_ADVERTISE = "android.permission.BLUETOOTH_ADVERTISE";
    static BLUETOOTH_CONNECT = "android.permission.BLUETOOTH_CONNECT";
    static BLUETOOTH_PRIVILEGED = "android.permission.BLUETOOTH_PRIVILEGED";
    static BLUETOOTH_SCAN = "android.permission.BLUETOOTH_SCAN";
    static BODY_SENSORS = "android.permission.BODY_SENSORS";
    static BODY_SENSORS_BACKGROUND = "android.permission.BODY_SENSORS_BACKGROUND";
    static BROADCAST_PACKAGE_REMOVED = "android.permission.BROADCAST_PACKAGE_REMOVED";
    static BROADCAST_SMS = "android.permission.BROADCAST_SMS";
    static BROADCAST_STICKY = "android.permission.BROADCAST_STICKY";
    static BROADCAST_WAP_PUSH = "android.permission.BROADCAST_WAP_PUSH";
    static CALL_COMPANION_APP = "android.permission.CALL_COMPANION_APP";
    static CALL_PHONE = "android.permission.CALL_PHONE";
    static CALL_PRIVILEGED = "android.permission.CALL_PRIVILEGED";
    static CAMERA = "android.permission.CAMERA";
    static CAPTURE_AUDIO_OUTPUT = "android.permission.CAPTURE_AUDIO_OUTPUT";
    static CHANGE_COMPONENT_ENABLED_STATE = "android.permission.CHANGE_COMPONENT_ENABLED_STATE";
    static CHANGE_CONFIGURATION = "android.permission.CHANGE_CONFIGURATION";
    static CHANGE_NETWORK_STATE = "android.permission.CHANGE_NETWORK_STATE";
    static CHANGE_WIFI_MULTICAST_STATE = "android.permission.CHANGE_WIFI_MULTICAST_STATE";
    static CHANGE_WIFI_STATE = "android.permission.CHANGE_WIFI_STATE";
    static CLEAR_APP_CACHE = "android.permission.CLEAR_APP_CACHE";
    static CONFIGURE_WIFI_DISPLAY = "android.permission.CONFIGURE_WIFI_DISPLAY";
    static CONTROL_LOCATION_UPDATES = "android.permission.CONTROL_LOCATION_UPDATES";
    static CREDENTIAL_MANAGER_QUERY_CANDIDATE_CREDENTIALS = "android.permission.CREDENTIAL_MANAGER_QUERY_CANDIDATE_CREDENTIALS";
    static CREDENTIAL_MANAGER_SET_ALLOWED_PROVIDERS = "android.permission.CREDENTIAL_MANAGER_SET_ALLOWED_PROVIDERS";
    static CREDENTIAL_MANAGER_SET_ORIGIN = "android.permission.CREDENTIAL_MANAGER_SET_ORIGIN";
    static DELETE_CACHE_FILES = "android.permission.DELETE_CACHE_FILES";
    static DELETE_PACKAGES = "android.permission.DELETE_PACKAGES";
    static DELIVER_COMPANION_MESSAGES = "android.permission.DELIVER_COMPANION_MESSAGES";
    static DETECT_SCREEN_CAPTURE = "android.permission.DETECT_SCREEN_CAPTURE";
    static DIAGNOSTIC = "android.permission.DIAGNOSTIC";
    static DISABLE_KEYGUARD = "android.permission.DISABLE_KEYGUARD";
    static DUMP = "android.permission.DUMP";
    static ENFORCE_UPDATE_OWNERSHIP = "android.permission.ENFORCE_UPDATE_OWNERSHIP";
    static EXECUTE_APP_ACTION = "android.permission.EXECUTE_APP_ACTION";
    static EXPAND_STATUS_BAR = "android.permission.EXPAND_STATUS_BAR";
    static FACTORY_TEST = "android.permission.FACTORY_TEST";
    static FOREGROUND_SERVICE = "android.permission.FOREGROUND_SERVICE";
    static FOREGROUND_SERVICE_CAMERA = "android.permission.FOREGROUND_SERVICE_CAMERA";
    static FOREGROUND_SERVICE_CONNECTED_DEVICE = "android.permission.FOREGROUND_SERVICE_CONNECTED_DEVICE";
    static FOREGROUND_SERVICE_DATA_SYNC = "android.permission.FOREGROUND_SERVICE_DATA_SYNC";
    static FOREGROUND_SERVICE_HEALTH = "android.permission.FOREGROUND_SERVICE_HEALTH";
    static FOREGROUND_SERVICE_LOCATION = "android.permission.FOREGROUND_SERVICE_LOCATION";
    static FOREGROUND_SERVICE_MEDIA_PLAYBACK = "android.permission.FOREGROUND_SERVICE_MEDIA_PLAYBACK";
    static FOREGROUND_SERVICE_MEDIA_PROJECTION = "android.permission.FOREGROUND_SERVICE_MEDIA_PROJECTION";
    static FOREGROUND_SERVICE_MICROPHONE = "android.permission.FOREGROUND_SERVICE_MICROPHONE";
    static FOREGROUND_SERVICE_PHONE_CALL = "android.permission.FOREGROUND_SERVICE_PHONE_CALL";
    static FOREGROUND_SERVICE_REMOTE_MESSAGING = "android.permission.FOREGROUND_SERVICE_REMOTE_MESSAGING";
    static FOREGROUND_SERVICE_SPECIAL_USE = "android.permission.FOREGROUND_SERVICE_SPECIAL_USE";
    static FOREGROUND_SERVICE_SYSTEM_EXEMPTED = "android.permission.FOREGROUND_SERVICE_SYSTEM_EXEMPTED";
    static GET_ACCOUNTS = "android.permission.GET_ACCOUNTS";
    static GET_ACCOUNTS_PRIVILEGED = "android.permission.GET_ACCOUNTS_PRIVILEGED";
    static GET_PACKAGE_SIZE = "android.permission.GET_PACKAGE_SIZE";
    /** @deprecated */

    static GET_TASKS = "android.permission.GET_TASKS";
    static GLOBAL_SEARCH = "android.permission.GLOBAL_SEARCH";
    static HIDE_OVERLAY_WINDOWS = "android.permission.HIDE_OVERLAY_WINDOWS";
    static HIGH_SAMPLING_RATE_SENSORS = "android.permission.HIGH_SAMPLING_RATE_SENSORS";
    static INSTALL_LOCATION_PROVIDER = "android.permission.INSTALL_LOCATION_PROVIDER";
    static INSTALL_PACKAGES = "android.permission.INSTALL_PACKAGES";
    static INSTALL_SHORTCUT = "com.android.launcher.permission.INSTALL_SHORTCUT";
    static INSTANT_APP_FOREGROUND_SERVICE = "android.permission.INSTANT_APP_FOREGROUND_SERVICE";
    static INTERACT_ACROSS_PROFILES = "android.permission.INTERACT_ACROSS_PROFILES";
    static INTERNET = "android.permission.INTERNET";
    static KILL_BACKGROUND_PROCESSES = "android.permission.KILL_BACKGROUND_PROCESSES";
    static LAUNCH_CAPTURE_CONTENT_ACTIVITY_FOR_NOTE = "android.permission.LAUNCH_CAPTURE_CONTENT_ACTIVITY_FOR_NOTE";
    static LAUNCH_MULTI_PANE_SETTINGS_DEEP_LINK = "android.permission.LAUNCH_MULTI_PANE_SETTINGS_DEEP_LINK";
    static LOADER_USAGE_STATS = "android.permission.LOADER_USAGE_STATS";
    static LOCATION_HARDWARE = "android.permission.LOCATION_HARDWARE";
    static MANAGE_DEVICE_LOCK_STATE = "android.permission.MANAGE_DEVICE_LOCK_STATE";
    static MANAGE_DEVICE_POLICY_ACCESSIBILITY = "android.permission.MANAGE_DEVICE_POLICY_ACCESSIBILITY";
    static MANAGE_DEVICE_POLICY_ACCOUNT_MANAGEMENT = "android.permission.MANAGE_DEVICE_POLICY_ACCOUNT_MANAGEMENT";
    static MANAGE_DEVICE_POLICY_ACROSS_USERS = "android.permission.MANAGE_DEVICE_POLICY_ACROSS_USERS";
    static MANAGE_DEVICE_POLICY_ACROSS_USERS_FULL = "android.permission.MANAGE_DEVICE_POLICY_ACROSS_USERS_FULL";
    static MANAGE_DEVICE_POLICY_ACROSS_USERS_SECURITY_CRITICAL = "android.permission.MANAGE_DEVICE_POLICY_ACROSS_USERS_SECURITY_CRITICAL";
    static MANAGE_DEVICE_POLICY_AIRPLANE_MODE = "android.permission.MANAGE_DEVICE_POLICY_AIRPLANE_MODE";
    static MANAGE_DEVICE_POLICY_APPS_CONTROL = "android.permission.MANAGE_DEVICE_POLICY_APPS_CONTROL";
    static MANAGE_DEVICE_POLICY_APP_RESTRICTIONS = "android.permission.MANAGE_DEVICE_POLICY_APP_RESTRICTIONS";
    static MANAGE_DEVICE_POLICY_APP_USER_DATA = "android.permission.MANAGE_DEVICE_POLICY_APP_USER_DATA";
    static MANAGE_DEVICE_POLICY_AUDIO_OUTPUT = "android.permission.MANAGE_DEVICE_POLICY_AUDIO_OUTPUT";
    static MANAGE_DEVICE_POLICY_AUTOFILL = "android.permission.MANAGE_DEVICE_POLICY_AUTOFILL";
    static MANAGE_DEVICE_POLICY_BACKUP_SERVICE = "android.permission.MANAGE_DEVICE_POLICY_BACKUP_SERVICE";
    static MANAGE_DEVICE_POLICY_BLUETOOTH = "android.permission.MANAGE_DEVICE_POLICY_BLUETOOTH";
    static MANAGE_DEVICE_POLICY_BUGREPORT = "android.permission.MANAGE_DEVICE_POLICY_BUGREPORT";
    static MANAGE_DEVICE_POLICY_CALLS = "android.permission.MANAGE_DEVICE_POLICY_CALLS";
    static MANAGE_DEVICE_POLICY_CAMERA = "android.permission.MANAGE_DEVICE_POLICY_CAMERA";
    static MANAGE_DEVICE_POLICY_CERTIFICATES = "android.permission.MANAGE_DEVICE_POLICY_CERTIFICATES";
    static MANAGE_DEVICE_POLICY_COMMON_CRITERIA_MODE = "android.permission.MANAGE_DEVICE_POLICY_COMMON_CRITERIA_MODE";
    static MANAGE_DEVICE_POLICY_DEBUGGING_FEATURES = "android.permission.MANAGE_DEVICE_POLICY_DEBUGGING_FEATURES";
    static MANAGE_DEVICE_POLICY_DEFAULT_SMS = "android.permission.MANAGE_DEVICE_POLICY_DEFAULT_SMS";
    static MANAGE_DEVICE_POLICY_DEVICE_IDENTIFIERS = "android.permission.MANAGE_DEVICE_POLICY_DEVICE_IDENTIFIERS";
    static MANAGE_DEVICE_POLICY_DISPLAY = "android.permission.MANAGE_DEVICE_POLICY_DISPLAY";
    static MANAGE_DEVICE_POLICY_FACTORY_RESET = "android.permission.MANAGE_DEVICE_POLICY_FACTORY_RESET";
    static MANAGE_DEVICE_POLICY_FUN = "android.permission.MANAGE_DEVICE_POLICY_FUN";
    static MANAGE_DEVICE_POLICY_INPUT_METHODS = "android.permission.MANAGE_DEVICE_POLICY_INPUT_METHODS";
    static MANAGE_DEVICE_POLICY_INSTALL_UNKNOWN_SOURCES = "android.permission.MANAGE_DEVICE_POLICY_INSTALL_UNKNOWN_SOURCES";
    static MANAGE_DEVICE_POLICY_KEEP_UNINSTALLED_PACKAGES = "android.permission.MANAGE_DEVICE_POLICY_KEEP_UNINSTALLED_PACKAGES";
    static MANAGE_DEVICE_POLICY_KEYGUARD = "android.permission.MANAGE_DEVICE_POLICY_KEYGUARD";
    static MANAGE_DEVICE_POLICY_LOCALE = "android.permission.MANAGE_DEVICE_POLICY_LOCALE";
    static MANAGE_DEVICE_POLICY_LOCATION = "android.permission.MANAGE_DEVICE_POLICY_LOCATION";
    static MANAGE_DEVICE_POLICY_LOCK = "android.permission.MANAGE_DEVICE_POLICY_LOCK";
    static MANAGE_DEVICE_POLICY_LOCK_CREDENTIALS = "android.permission.MANAGE_DEVICE_POLICY_LOCK_CREDENTIALS";
    static MANAGE_DEVICE_POLICY_LOCK_TASK = "android.permission.MANAGE_DEVICE_POLICY_LOCK_TASK";
    static MANAGE_DEVICE_POLICY_METERED_DATA = "android.permission.MANAGE_DEVICE_POLICY_METERED_DATA";
    static MANAGE_DEVICE_POLICY_MICROPHONE = "android.permission.MANAGE_DEVICE_POLICY_MICROPHONE";
    static MANAGE_DEVICE_POLICY_MOBILE_NETWORK = "android.permission.MANAGE_DEVICE_POLICY_MOBILE_NETWORK";
    static MANAGE_DEVICE_POLICY_MODIFY_USERS = "android.permission.MANAGE_DEVICE_POLICY_MODIFY_USERS";
    static MANAGE_DEVICE_POLICY_MTE = "android.permission.MANAGE_DEVICE_POLICY_MTE";
    static MANAGE_DEVICE_POLICY_NEARBY_COMMUNICATION = "android.permission.MANAGE_DEVICE_POLICY_NEARBY_COMMUNICATION";
    static MANAGE_DEVICE_POLICY_NETWORK_LOGGING = "android.permission.MANAGE_DEVICE_POLICY_NETWORK_LOGGING";
    static MANAGE_DEVICE_POLICY_ORGANIZATION_IDENTITY = "android.permission.MANAGE_DEVICE_POLICY_ORGANIZATION_IDENTITY";
    static MANAGE_DEVICE_POLICY_OVERRIDE_APN = "android.permission.MANAGE_DEVICE_POLICY_OVERRIDE_APN";
    static MANAGE_DEVICE_POLICY_PACKAGE_STATE = "android.permission.MANAGE_DEVICE_POLICY_PACKAGE_STATE";
    static MANAGE_DEVICE_POLICY_PHYSICAL_MEDIA = "android.permission.MANAGE_DEVICE_POLICY_PHYSICAL_MEDIA";
    static MANAGE_DEVICE_POLICY_PRINTING = "android.permission.MANAGE_DEVICE_POLICY_PRINTING";
    static MANAGE_DEVICE_POLICY_PRIVATE_DNS = "android.permission.MANAGE_DEVICE_POLICY_PRIVATE_DNS";
    static MANAGE_DEVICE_POLICY_PROFILES = "android.permission.MANAGE_DEVICE_POLICY_PROFILES";
    static MANAGE_DEVICE_POLICY_PROFILE_INTERACTION = "android.permission.MANAGE_DEVICE_POLICY_PROFILE_INTERACTION";
    static MANAGE_DEVICE_POLICY_PROXY = "android.permission.MANAGE_DEVICE_POLICY_PROXY";
    static MANAGE_DEVICE_POLICY_QUERY_SYSTEM_UPDATES = "android.permission.MANAGE_DEVICE_POLICY_QUERY_SYSTEM_UPDATES";
    static MANAGE_DEVICE_POLICY_RESET_PASSWORD = "android.permission.MANAGE_DEVICE_POLICY_RESET_PASSWORD";
    static MANAGE_DEVICE_POLICY_RESTRICT_PRIVATE_DNS = "android.permission.MANAGE_DEVICE_POLICY_RESTRICT_PRIVATE_DNS";
    static MANAGE_DEVICE_POLICY_RUNTIME_PERMISSIONS = "android.permission.MANAGE_DEVICE_POLICY_RUNTIME_PERMISSIONS";
    static MANAGE_DEVICE_POLICY_RUN_IN_BACKGROUND = "android.permission.MANAGE_DEVICE_POLICY_RUN_IN_BACKGROUND";
    static MANAGE_DEVICE_POLICY_SAFE_BOOT = "android.permission.MANAGE_DEVICE_POLICY_SAFE_BOOT";
    static MANAGE_DEVICE_POLICY_SCREEN_CAPTURE = "android.permission.MANAGE_DEVICE_POLICY_SCREEN_CAPTURE";
    static MANAGE_DEVICE_POLICY_SCREEN_CONTENT = "android.permission.MANAGE_DEVICE_POLICY_SCREEN_CONTENT";
    static MANAGE_DEVICE_POLICY_SECURITY_LOGGING = "android.permission.MANAGE_DEVICE_POLICY_SECURITY_LOGGING";
    static MANAGE_DEVICE_POLICY_SETTINGS = "android.permission.MANAGE_DEVICE_POLICY_SETTINGS";
    static MANAGE_DEVICE_POLICY_SMS = "android.permission.MANAGE_DEVICE_POLICY_SMS";
    static MANAGE_DEVICE_POLICY_STATUS_BAR = "android.permission.MANAGE_DEVICE_POLICY_STATUS_BAR";
    static MANAGE_DEVICE_POLICY_SUPPORT_MESSAGE = "android.permission.MANAGE_DEVICE_POLICY_SUPPORT_MESSAGE";
    static MANAGE_DEVICE_POLICY_SUSPEND_PERSONAL_APPS = "android.permission.MANAGE_DEVICE_POLICY_SUSPEND_PERSONAL_APPS";
    static MANAGE_DEVICE_POLICY_SYSTEM_APPS = "android.permission.MANAGE_DEVICE_POLICY_SYSTEM_APPS";
    static MANAGE_DEVICE_POLICY_SYSTEM_DIALOGS = "android.permission.MANAGE_DEVICE_POLICY_SYSTEM_DIALOGS";
    static MANAGE_DEVICE_POLICY_SYSTEM_UPDATES = "android.permission.MANAGE_DEVICE_POLICY_SYSTEM_UPDATES";
    static MANAGE_DEVICE_POLICY_TIME = "android.permission.MANAGE_DEVICE_POLICY_TIME";
    static MANAGE_DEVICE_POLICY_USB_DATA_SIGNALLING = "android.permission.MANAGE_DEVICE_POLICY_USB_DATA_SIGNALLING";
    static MANAGE_DEVICE_POLICY_USB_FILE_TRANSFER = "android.permission.MANAGE_DEVICE_POLICY_USB_FILE_TRANSFER";
    static MANAGE_DEVICE_POLICY_USERS = "android.permission.MANAGE_DEVICE_POLICY_USERS";
    static MANAGE_DEVICE_POLICY_VPN = "android.permission.MANAGE_DEVICE_POLICY_VPN";
    static MANAGE_DEVICE_POLICY_WALLPAPER = "android.permission.MANAGE_DEVICE_POLICY_WALLPAPER";
    static MANAGE_DEVICE_POLICY_WIFI = "android.permission.MANAGE_DEVICE_POLICY_WIFI";
    static MANAGE_DEVICE_POLICY_WINDOWS = "android.permission.MANAGE_DEVICE_POLICY_WINDOWS";
    static MANAGE_DEVICE_POLICY_WIPE_DATA = "android.permission.MANAGE_DEVICE_POLICY_WIPE_DATA";
    static MANAGE_DOCUMENTS = "android.permission.MANAGE_DOCUMENTS";
    static MANAGE_EXTERNAL_STORAGE = "android.permission.MANAGE_EXTERNAL_STORAGE";
    static MANAGE_MEDIA = "android.permission.MANAGE_MEDIA";
    static MANAGE_ONGOING_CALLS = "android.permission.MANAGE_ONGOING_CALLS";
    static MANAGE_OWN_CALLS = "android.permission.MANAGE_OWN_CALLS";
    static MANAGE_WIFI_INTERFACES = "android.permission.MANAGE_WIFI_INTERFACES";
    static MANAGE_WIFI_NETWORK_SELECTION = "android.permission.MANAGE_WIFI_NETWORK_SELECTION";
    static MASTER_CLEAR = "android.permission.MASTER_CLEAR";
    static MEDIA_CONTENT_CONTROL = "android.permission.MEDIA_CONTENT_CONTROL";
    static MODIFY_AUDIO_SETTINGS = "android.permission.MODIFY_AUDIO_SETTINGS";
    static MODIFY_PHONE_STATE = "android.permission.MODIFY_PHONE_STATE";
    static MOUNT_FORMAT_FILESYSTEMS = "android.permission.MOUNT_FORMAT_FILESYSTEMS";
    static MOUNT_UNMOUNT_FILESYSTEMS = "android.permission.MOUNT_UNMOUNT_FILESYSTEMS";
    static NEARBY_WIFI_DEVICES = "android.permission.NEARBY_WIFI_DEVICES";
    static NFC = "android.permission.NFC";
    static NFC_PREFERRED_PAYMENT_INFO = "android.permission.NFC_PREFERRED_PAYMENT_INFO";
    static NFC_TRANSACTION_EVENT = "android.permission.NFC_TRANSACTION_EVENT";
    static OVERRIDE_WIFI_CONFIG = "android.permission.OVERRIDE_WIFI_CONFIG";
    static PACKAGE_USAGE_STATS = "android.permission.PACKAGE_USAGE_STATS";
    /** @deprecated */

    static PERSISTENT_ACTIVITY = "android.permission.PERSISTENT_ACTIVITY";
    static POST_NOTIFICATIONS = "android.permission.POST_NOTIFICATIONS";
    /** @deprecated */

    static PROCESS_OUTGOING_CALLS = "android.permission.PROCESS_OUTGOING_CALLS";
    static PROVIDE_OWN_AUTOFILL_SUGGESTIONS = "android.permission.PROVIDE_OWN_AUTOFILL_SUGGESTIONS";
    static PROVIDE_REMOTE_CREDENTIALS = "android.permission.PROVIDE_REMOTE_CREDENTIALS";
    static QUERY_ALL_PACKAGES = "android.permission.QUERY_ALL_PACKAGES";
    static READ_ASSISTANT_APP_SEARCH_DATA = "android.permission.READ_ASSISTANT_APP_SEARCH_DATA";
    static READ_BASIC_PHONE_STATE = "android.permission.READ_BASIC_PHONE_STATE";
    static READ_CALENDAR = "android.permission.READ_CALENDAR";
    static READ_CALL_LOG = "android.permission.READ_CALL_LOG";
    static READ_CONTACTS = "android.permission.READ_CONTACTS";
    static READ_EXTERNAL_STORAGE = "android.permission.READ_EXTERNAL_STORAGE";
    static READ_HOME_APP_SEARCH_DATA = "android.permission.READ_HOME_APP_SEARCH_DATA";
    /** @deprecated */

    static READ_INPUT_STATE = "android.permission.READ_INPUT_STATE";
    static READ_LOGS = "android.permission.READ_LOGS";
    static READ_MEDIA_AUDIO = "android.permission.READ_MEDIA_AUDIO";
    static READ_MEDIA_IMAGES = "android.permission.READ_MEDIA_IMAGES";
    static READ_MEDIA_VIDEO = "android.permission.READ_MEDIA_VIDEO";
    static READ_MEDIA_VISUAL_USER_SELECTED = "android.permission.READ_MEDIA_VISUAL_USER_SELECTED";
    static READ_NEARBY_STREAMING_POLICY = "android.permission.READ_NEARBY_STREAMING_POLICY";
    static READ_PHONE_NUMBERS = "android.permission.READ_PHONE_NUMBERS";
    static READ_PHONE_STATE = "android.permission.READ_PHONE_STATE";
    static READ_PRECISE_PHONE_STATE = "android.permission.READ_PRECISE_PHONE_STATE";
    static READ_SMS = "android.permission.READ_SMS";
    static READ_SYNC_SETTINGS = "android.permission.READ_SYNC_SETTINGS";
    static READ_SYNC_STATS = "android.permission.READ_SYNC_STATS";
    static READ_VOICEMAIL = "com.android.voicemail.permission.READ_VOICEMAIL";
    static REBOOT = "android.permission.REBOOT";
    static RECEIVE_BOOT_COMPLETED = "android.permission.RECEIVE_BOOT_COMPLETED";
    static RECEIVE_MMS = "android.permission.RECEIVE_MMS";
    static RECEIVE_SMS = "android.permission.RECEIVE_SMS";
    static RECEIVE_WAP_PUSH = "android.permission.RECEIVE_WAP_PUSH";
    static RECORD_AUDIO = "android.permission.RECORD_AUDIO";
    static REORDER_TASKS = "android.permission.REORDER_TASKS";
    static REQUEST_COMPANION_PROFILE_APP_STREAMING = "android.permission.REQUEST_COMPANION_PROFILE_APP_STREAMING";
    static REQUEST_COMPANION_PROFILE_AUTOMOTIVE_PROJECTION = "android.permission.REQUEST_COMPANION_PROFILE_AUTOMOTIVE_PROJECTION";
    static REQUEST_COMPANION_PROFILE_COMPUTER = "android.permission.REQUEST_COMPANION_PROFILE_COMPUTER";
    static REQUEST_COMPANION_PROFILE_GLASSES = "android.permission.REQUEST_COMPANION_PROFILE_GLASSES";
    static REQUEST_COMPANION_PROFILE_NEARBY_DEVICE_STREAMING = "android.permission.REQUEST_COMPANION_PROFILE_NEARBY_DEVICE_STREAMING";
    static REQUEST_COMPANION_PROFILE_WATCH = "android.permission.REQUEST_COMPANION_PROFILE_WATCH";
    static REQUEST_COMPANION_RUN_IN_BACKGROUND = "android.permission.REQUEST_COMPANION_RUN_IN_BACKGROUND";
    static REQUEST_COMPANION_SELF_MANAGED = "android.permission.REQUEST_COMPANION_SELF_MANAGED";
    static REQUEST_COMPANION_START_FOREGROUND_SERVICES_FROM_BACKGROUND = "android.permission.REQUEST_COMPANION_START_FOREGROUND_SERVICES_FROM_BACKGROUND";
    static REQUEST_COMPANION_USE_DATA_IN_BACKGROUND = "android.permission.REQUEST_COMPANION_USE_DATA_IN_BACKGROUND";
    static REQUEST_DELETE_PACKAGES = "android.permission.REQUEST_DELETE_PACKAGES";
    static REQUEST_IGNORE_BATTERY_OPTIMIZATIONS = "android.permission.REQUEST_IGNORE_BATTERY_OPTIMIZATIONS";
    static REQUEST_INSTALL_PACKAGES = "android.permission.REQUEST_INSTALL_PACKAGES";
    static REQUEST_OBSERVE_COMPANION_DEVICE_PRESENCE = "android.permission.REQUEST_OBSERVE_COMPANION_DEVICE_PRESENCE";
    static REQUEST_PASSWORD_COMPLEXITY = "android.permission.REQUEST_PASSWORD_COMPLEXITY";
    /** @deprecated */

    static RESTART_PACKAGES = "android.permission.RESTART_PACKAGES";
    static RUN_USER_INITIATED_JOBS = "android.permission.RUN_USER_INITIATED_JOBS";
    static SCHEDULE_EXACT_ALARM = "android.permission.SCHEDULE_EXACT_ALARM";
    static SEND_RESPOND_VIA_MESSAGE = "android.permission.SEND_RESPOND_VIA_MESSAGE";
    static SEND_SMS = "android.permission.SEND_SMS";
    static SET_ALARM = "com.android.alarm.permission.SET_ALARM";
    static SET_ALWAYS_FINISH = "android.permission.SET_ALWAYS_FINISH";
    static SET_ANIMATION_SCALE = "android.permission.SET_ANIMATION_SCALE";
    static SET_DEBUG_APP = "android.permission.SET_DEBUG_APP";
    /** @deprecated */

    static SET_PREFERRED_APPLICATIONS = "android.permission.SET_PREFERRED_APPLICATIONS";
    static SET_PROCESS_LIMIT = "android.permission.SET_PROCESS_LIMIT";
    static SET_TIME = "android.permission.SET_TIME";
    static SET_TIME_ZONE = "android.permission.SET_TIME_ZONE";
    static SET_WALLPAPER = "android.permission.SET_WALLPAPER";
    static SET_WALLPAPER_HINTS = "android.permission.SET_WALLPAPER_HINTS";
    static SIGNAL_PERSISTENT_PROCESSES = "android.permission.SIGNAL_PERSISTENT_PROCESSES";
    /** @deprecated */

    static SMS_FINANCIAL_TRANSACTIONS = "android.permission.SMS_FINANCIAL_TRANSACTIONS";
    static START_FOREGROUND_SERVICES_FROM_BACKGROUND = "android.permission.START_FOREGROUND_SERVICES_FROM_BACKGROUND";
    static START_VIEW_APP_FEATURES = "android.permission.START_VIEW_APP_FEATURES";
    static START_VIEW_PERMISSION_USAGE = "android.permission.START_VIEW_PERMISSION_USAGE";
    static STATUS_BAR = "android.permission.STATUS_BAR";
    static SUBSCRIBE_TO_KEYGUARD_LOCKED_STATE = "android.permission.SUBSCRIBE_TO_KEYGUARD_LOCKED_STATE";
    // 浮窗
    static SYSTEM_ALERT_WINDOW = "android.permission.SYSTEM_ALERT_WINDOW";
    static TRANSMIT_IR = "android.permission.TRANSMIT_IR";
    static TURN_SCREEN_ON = "android.permission.TURN_SCREEN_ON";
    static UNINSTALL_SHORTCUT = "com.android.launcher.permission.UNINSTALL_SHORTCUT";
    static UPDATE_DEVICE_STATS = "android.permission.UPDATE_DEVICE_STATS";
    static UPDATE_PACKAGES_WITHOUT_USER_ACTION = "android.permission.UPDATE_PACKAGES_WITHOUT_USER_ACTION";
    static USE_BIOMETRIC = "android.permission.USE_BIOMETRIC";
    static USE_EXACT_ALARM = "android.permission.USE_EXACT_ALARM";
    /** @deprecated */

    static USE_FINGERPRINT = "android.permission.USE_FINGERPRINT";
    static USE_FULL_SCREEN_INTENT = "android.permission.USE_FULL_SCREEN_INTENT";
    static USE_ICC_AUTH_WITH_DEVICE_IDENTIFIER = "android.permission.USE_ICC_AUTH_WITH_DEVICE_IDENTIFIER";
    static USE_SIP = "android.permission.USE_SIP";
    static UWB_RANGING = "android.permission.UWB_RANGING";
    static VIBRATE = "android.permission.VIBRATE";
    static WAKE_LOCK = "android.permission.WAKE_LOCK";
    static WRITE_APN_SETTINGS = "android.permission.WRITE_APN_SETTINGS";
    static WRITE_CALENDAR = "android.permission.WRITE_CALENDAR";
    static WRITE_CALL_LOG = "android.permission.WRITE_CALL_LOG";
    static WRITE_CONTACTS = "android.permission.WRITE_CONTACTS";
    static WRITE_EXTERNAL_STORAGE = "android.permission.WRITE_EXTERNAL_STORAGE";
    static WRITE_GSERVICES = "android.permission.WRITE_GSERVICES";
    static WRITE_SECURE_SETTINGS = "android.permission.WRITE_SECURE_SETTINGS";
    static WRITE_SETTINGS = "android.permission.WRITE_SETTINGS";
    static WRITE_SYNC_SETTINGS = "android.permission.WRITE_SYNC_SETTINGS";
    static WRITE_VOICEMAIL = "com.android.voicemail.permission.WRITE_VOICEMAIL";
}
export type PermissionType = keyof Permission
export class Manifest{
    static Permission = Permission;
    static PermissionGroup = PermissionGroup;
}

