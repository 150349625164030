import {createLogger} from "#/libs/fanfanlo/log/Log";
import {autoWebViewJs, AutoWebViewJs} from "#/android/AutoWebViewJs";
import {Log} from "../../libs/fanfanlo/log/Log";
import {
    IPermission,
    PermissionConf
} from "#/android/components/PermissionInterfaces";
import {EventDispatcher} from "#/libs/fanfanlo/events/EventDispatcher";
import {DataEvent} from "#/libs/fanfanlo/events/DataEvent";
import {PermissionEvent} from "#/android/components/PermissionEvent";
import _ from "lodash";
import {callLater} from "#/libs/fanfanlo/utils/callLater/callLater";
import {documentEventTypeDocumentIsShow} from "#/libs/fanfanlo/utils/documentUtils";

export class Permission extends EventDispatcher implements IPermission{
    static dispacher = new EventDispatcher();
    static eventPermissionMayUpdated = "eventPermissionMayUpdated"
    static eventComplete = "complete";
    logger = Log.createCountedLogger(false, this.constructor.name)
    conf!:PermissionConf
    // 如果调用过ask，数据类型不匹配会是undefined，授权和未授权是true和false
    isSuccess:boolean|undefined;
    androidPermissionList:string[] = []
    exitFunList :(()=>void)[] = []
    isComplete:boolean = false
    isTerminate:boolean = false
    init(conf:PermissionConf){
        this.conf = conf;
    }
    runCheck(){
        let res:any
        let hasPermission:boolean|undefined;
        let strData:string
        let {print, error, warn, logger} = this.logger.sub(false, "fn-runCheck");
        if(this.conf.scripts.check.isRunner){
            res = autoWebViewJs.callScriptRunner(this.conf.scripts.check.script)
            strData = res.runnerResult;
        }else{
            res = autoWebViewJs.callScript(this.conf.scripts.check.script)
            if(res.errMsg){
                error(res.errMsg)
            }
            strData = res.data;
        }

        if(strData){
            try {
                hasPermission = JSON.parse(strData)
            }catch (e) {
                error("json parese strData", e)
            }
        }
        return hasPermission
    }
    check(){
        this.isSuccess = undefined;
        let {print, error, warn, logger} = this.logger.sub(false, "fn_check");
        let hasPermission = this.runCheck();
        if(!_.isBoolean(hasPermission)){
            error("hasPermission is not bool", hasPermission)
            hasPermission = false;
        }else{
            this.isSuccess = hasPermission;
        }
        if(hasPermission === true){
            callLater(this.complete, 1)
        }
        return this.isSuccess;
    }
    checkOrAsk =()=>{
        this.isTerminate = false;
        this.isComplete = false;
        let hasPermission = this.check();
        if(!hasPermission){
            this.runAsk()
            return false;
        }
        return hasPermission;
    }
    runAsk =()=>{
        this.conf.askUi.show(this)
    }
    ask (){
        let res:any
        if(this.conf.scripts.ask.isRunner){
            res = autoWebViewJs.callScriptRunner(this.conf.scripts.ask.script)
        }else{
            res = autoWebViewJs.callScript(this.conf.scripts.ask.script)
        }
// 在子类里覆写吧
//         this.dispatchMayUpdatedEvent(undefined, undefined)
    }

    callAsk(): void {
        this.listenAskBack();
        this.ask();
    }
    protected listenAskBack=()=>{
        if(!this.conf.listenResume)return;
        document.addEventListener(documentEventTypeDocumentIsShow, this.onDocumentShow)
    }
    protected onDocumentShow=(e:Event)=>{
        document.removeEventListener(documentEventTypeDocumentIsShow, this.onDocumentShow);
        this.askComplete();
    }
    dispatchMayUpdatedEvent(bl:boolean|undefined = undefined, extra:any|undefined = undefined){
        for (let i = 0; i < this.androidPermissionList.length; i++) {
            let p = this.androidPermissionList[i];
            let event = new PermissionEvent(p, bl, extra, this)
            Permission.dispacher.dispatchEvent(event);
            this.dispatchEvent(event)
        }
    }

    askComplete=()=>{
        if(this.isComplete){
            return;
        }
        this.check();
        this.dispatchMayUpdatedEvent(this.isSuccess, undefined);
        this.complete();
    }
    complete=()=>{
        this.isComplete = true;
        this.clear();
        this.dispatchEvent(new DataEvent(Permission.eventComplete));
    }
    clear(){
        for (let i = 0; i < this.exitFunList.length; i++) {
            this.exitFunList[i]()
        }
        this.exitFunList.length = 0;
    }
}
