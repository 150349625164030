import {Permission} from "#/android/components/Permission";
import {PermissionAskUiConf, PermissionConf} from "#/android/components/PermissionInterfaces";
import {PermissionAskUi} from "#/android/components/PermissionAskUi";
import i18n from "i18next";
import {storageKeys} from "#/common/storage/storageKeys";
import {AutoWebViewJs} from "#/android/AutoWebViewJs";
import {WebViewJs} from "#/android/WebViewJs";
import {documentEventTypeDocumentIsShow} from "#/libs/fanfanlo/utils/documentUtils";
import {Log} from "#/libs/fanfanlo/log/Log";
import {Manifest} from "#/android/components/Manifest";
import {androidDispatchers} from "#/android/androidDispatchers";



function callback(isOk:boolean, rejectMsg:string):void{

}

function createAskScript(eventType:string, id:string){
    let script = `
var bl = com.fanfanlo.lib.permissions.FloatPermission.openPermission(function(bl){
        webViewJs.callJsEvent("${eventType}", bl, "${id}");
    });
    webViewJs.callJsEvent("${eventType}", bl, "${id}");
`
    const conf = {
        forceStopTime:0,
        onlyRunSingle :true,
        stopPrevIfOnlyRunSingle: true,
        injects:[["webViewJs"]],
        script
    }
    return AutoWebViewJs.confToScript(conf);
}
function createPermissionConf(askUi:PermissionAskUi, eventType:string, id:string){

    let conf:PermissionConf = {
        askUi: askUi, callback,  permissionId: 0,
        scripts: {
            ask: {
                script:createAskScript(eventType, id),
                isRunner:true
            },
            check: {
                script:`var bl = com.fanfanlo.lib.permissions.FloatPermission.hasPermission();\n bl;`,
                isRunner:false
            }
        },
        listenResume:false
    }
    return conf
}

function createAskUi(){
    let conf:PermissionAskUiConf = {
        cancelButtonText: i18n.t("common.cancel"),
        checkBoxText: i18n.t("permissions.float.askPermission.checkBoxText"),
        hasCheckBox: true,
        okButtonText: i18n.t("common.ok"),
        storageKey: storageKeys.permissions.float.showAsk,
        text: i18n.t("permissions.float.askPermission.content"),
        title:i18n.t("permissions.float.askPermission.title")
    }
    let askUi = new PermissionAskUi();
    askUi.conf = conf;
    return askUi
}

function listenAskBackEvent(eventType:string, permission:Permission){
    let print = Log.createCountedLogger(false, "fn-listenAskBackEvent").print;

    const index = WebViewJs.instance.shellListen(androidDispatchers.webViewJs.name, eventType, eventType, true, onBack);
    print("index is", index);
    function onBack(data:any){
        print("fn-onBack result is", data)
    }
    document.addEventListener(documentEventTypeDocumentIsShow, onDocumentShow)
    function onDocumentShow(){
        document.removeEventListener(documentEventTypeDocumentIsShow, onDocumentShow)
        let res = WebViewJs.instance.shellRemoveListen(androidDispatchers.webViewJs.name, index);
        permission.askComplete();
        print("android listener removeListen", res);
    }

}
export function createFloatPermission(){
    let askUi = createAskUi();
    let id = Date.now().toString()
    let eventType = `onFloatPermissionSettingResult${id}`
    let permissionConf = createPermissionConf(askUi, eventType, id)
    let floatPermission = new FloatPermission();
    floatPermission.conf = permissionConf;

    return floatPermission;
}

class FloatPermission extends Permission{
    constructor() {
        super();
        this.androidPermissionList = [
            Manifest.Permission.SYSTEM_ALERT_WINDOW
        ]
    }
    ask(){
        let id = Date.now().toString()
        let eventType = `onFloatPermissionSettingResult${id}`
        this.conf.scripts.ask.script = createAskScript(eventType, id);
        listenAskBackEvent(eventType, this);
        let res = super.ask();
        this.dispatchMayUpdatedEvent(undefined, undefined)
    }
}
