import {IPermission, IPermissionAskUi, PermissionAskUiConf} from "#/android/components/PermissionInterfaces";
import {storage} from "#/common/storage/storage";


export class PermissionAskUi implements IPermissionAskUi{
    protected permission!:IPermission;
    conf!:PermissionAskUiConf;
    protected dialog:HTMLDialogElement | undefined
    protected exited = false;
    constructor() {
    }
    private showDialog=()=>{
        this.initDialog();
    }
    protected getCheckBoxValue(){
        let {storageKey} = this.conf;
        if(!storageKey)return undefined;

        const o = storage.getItem(storageKey);
        let show = true;
        if(o && o.hide == true){
            let now = Date.now()
            let time = o.time
            let diff = now - time;
            if(diff < 1000 * 60 * 60 * 24 * 15){
                show = false;
            }
        }
        return show;
    }
    protected createDialogInnerHtml(){
        let {text, title, hasCheckBox,checkBoxText,okButtonText, cancelButtonText} = this.conf
        let ct = hasCheckBox ? `<div style="flex;flex-direction: row"><input id="permissionAskHideCheckBox" type="checkbox" />
            <span>${checkBoxText}</span>
            </div> ` :''
        let innerHTML = `<div>
        <div>
        <div style="text-align: center">
        <b>${title}</b>
        </div>
        <p>${text}</p>
        ${ct}
<!--        ${hasCheckBox? '<p><input id="permissionAskHideCheckBox" type="checkbox">${checkBoxText}</p> ': ""}-->
        </div>
        <div style="text-align: right;margin-right: 20px">
        <a id="permissionAskCancelButton" href="#" style="margin-right: 20px">${cancelButtonText}</a>
        <a id="permissionAskOkButton">${okButtonText}</a></div>
        </div>`
        return innerHTML;
    }
    protected initDialog(){
        let checkBoxValue = this.getCheckBoxValue();
        if(checkBoxValue === false){
            this.callAsk();
            this.exit();
            return;
        }
        var dialog = document.createElement("dialog");
        this.dialog = dialog;
        dialog.innerHTML = this.createDialogInnerHtml();
        document.body.appendChild(dialog)
        this.initCloseButton();
        this.initOkButton();
        dialog.showModal();
    }
    protected initCloseButton(){
        let closeBtn = document.getElementById("permissionAskCancelButton")
        if(closeBtn){
            closeBtn.onclick = ()=>{
                this.closeDialog()
            }
        }
    }
    protected initOkButton(){
        let okBtn = document.getElementById("permissionAskOkButton")
        if(!okBtn)return;
        okBtn.onclick =  this.onOkClick.bind(this)
    }
    protected storeCheckValue =()=>{
        let {hasCheckBox, storageKey} = this.conf
        if(!hasCheckBox)return;
        let input = document.getElementById("permissionAskHideCheckBox")
        let checked  = false;
        if(input){
            checked = (input as any)["checked"];
        }
        if(checked){
            let o = {hide:true, time:Date.now()}
            storage.setItem(storageKey, o);
        }
    }
    protected callAsk(){
        this.permission.callAsk();
    }
    protected exit(){
        if(this.exited)return;
        this.exited = true;
    }
    protected closeDialog(){
        this.exit();
        if(!this.dialog)return;
        this.dialog.close();
        document.body.removeChild(this.dialog);
        this.dialog = undefined;
    }
    show =(permission:IPermission)=>{
        this.permission = permission;
        this.showDialog();
    }
    onOkClick(){
        this.storeCheckValue();
        this.callAsk();
        this.closeDialog();
    }
    onCloseClick(){
        this.closeDialog()
    }
}