import {EventDispatcher} from "#/libs/fanfanlo/events/EventDispatcher";
import {Permission} from "#/android/components/Permission";
import {DataEvent} from "#/libs/fanfanlo/events/DataEvent";
import {createFloatPermission} from "#/android/components/floatPermission";
import {createA11yPermission} from "#/android/components/A11yPermission";
import {Log} from "#/libs/fanfanlo/log/Log";

type Callback = (group:PermissionGroup, isSuccess:boolean)=>void
export class PermissionGroup extends EventDispatcher{
    static eventComplete = "complete";
    permissions!:Permission[]
    private index = -1;
    logger = Log.createCountedLogger(false, this.constructor.name)
    private callback?:Callback;
    constructor(permissions:Permission[] = []) {
        super();
        // this.logger = Log.createCountedLogger(false, this.constructor.name)
        this.permissions = permissions
    }
    run=()=>{
        this.checkNext();
    }
    private runPermission=()=>{
        if(this.permissions.length <= this.index){
            throw new Error("permission index out of bounds")
        }
        let p = this.permissions[this.index];
        p.addDomainEventListener(this, Permission.eventComplete, this.onPermissionComplete)
        p.checkOrAsk();
    }

    private onPermissionComplete=(e:DataEvent)=>{
        e.target.removeDomain(this);
        this.checkNext();
    }
    private checkNext =()=>{
        this.index++;
        if(this.index >= this.permissions.length){
            return this.dispatchComplete()
        }
        this.runPermission();
    }
    private dispatchComplete =()=>{
        this.dispatchEvent(new DataEvent(PermissionGroup.eventComplete))
        this.callback?.(this, this.isSuccess);
    }
    exit=()=>{
        for (let i = 0; i < this.permissions.length; i++) {
            this.permissions[i].removeDomain(this);
        }
    }
    get isSuccess(){
        let {print, error, warn, logger} = this.logger.sub(false, "fn_isSuccess");
        for (let i = 0; i < this.permissions.length; i++) {
            let p = this.permissions[i];
            print(`permission is ${p.constructor.name}, success is ${p.isSuccess}`)
            if(!p.isSuccess)return false;
        }
        return true
    }
    addCallback(callback:Callback){
        this.callback = callback;
        return this;
    }
}
