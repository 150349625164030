
import {askA11yPermissionDialogCreate} from "../../../common/ui/dialog/askA11yPermissionDialog";
import {storage} from "../../../common/storage/storage";


export function getOpenA11yPermission(){
    const android = window.android
    if(!android){
        alert("非安卓环境下不能调用安卓功能")
        return null
    }
    const s = android.onCallGetA11yServiceLiveInfo()
    const info = JSON.parse(s)
    return info;
}
export function checkOpenA11yPermissionWithAsk(){
    let info = getOpenA11yPermission();
    if(!info)return false
    if(!info.hasPermission){
        let key = "jumpAskA11yPermissionDialogForHalfMonth"
        const o = storage.getItem(key);
        let show = true;
        if(o && o.hide == true){
            let now = Date.now()
            let time = o.time
            let diff = now - time;
            if(diff < 1000 * 60 * 60 * 24 * 15){
                show = false
                android.onCallOpenA11yPermission()
                return false;
            }
        }
        askA11yPermissionDialogCreate("此功能需要辅助功能授权，如要授权请点击【确定】，放弃请点击【取消】", show, key);
        return false
    }

    if(!info.hasService || !info.isAlive){
        askA11yPermissionDialogCreate("辅助功能已崩溃，请重新授权，在授权页面如果辅助功能是开启状态就请先关闭然后再打开，如要授权请点击【确定】，放弃请点击【取消】", false);
        return false;
    }
    return true
}
